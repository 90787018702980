<template>
    <div class="waiting white-back flex-1">
        <el-empty :image="imgPath" description="审核失败" :image-size="467"/>
        <div class="flex-column flex-cc">
            <p>原因：{{reason}}</p>
            <el-button type="primary" @click="upDateInfo">修改</el-button>
        </div>

    </div>
</template>

<script>
    import imgPath from "@/assets/img/company_reject.png"

    export default {
        name: "certFail",
        data() {
            return {
                imgPath,
                reason:this.$route.query.reason
            }
        },
        methods:{
            upDateInfo(){
                this.$router.push({
                    name:'updateEnter'
                })
            }
        }
    }
</script>

<style scoped>
.waiting{padding-top: 4%;}
.waiting p{margin-bottom: 4%;}
</style>